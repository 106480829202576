
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import WorkingHoursTable from "@/components/administration/approvedWorkingHours/WorkingHoursTable.vue";
import WorkingHoursGeneratedFilesTable from "@/components/administration/approvedWorkingHours/WorkingHoursGeneratedFilesTable.vue";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import { api } from "@/api/api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { StoreState } from "@/store/store.state.interface";
import { useStore } from "@/shared/useHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

enum WorkingHourTabs {
  ApprovedWorkingHours = "approved",
  PaymentPendingWorkingHours = "paymentPending",
  GeneratedFiles = "generatedFiles",
}

export default defineComponent({
  name: "ApprovedWorkingHoursPage",
  components: { BaseLayout, WorkingHoursTable, WorkingHoursGeneratedFilesTable, BaseDatePicker },
  setup() {
    const store = useStore<StoreState>();
    const workingHours = ref<ApiGetWorkingHourDto[]>([]);
    const currentTab = ref<WorkingHourTabs>(WorkingHourTabs.ApprovedWorkingHours);
    const selectedRows = ref<ApiGetWorkingHourDto[]>([]);
    const fromDate = ref();
    const toDate = ref();
    const search = ref<string>("");
    const loading = ref<boolean>(false);

    // Reset selectedRows if tab is changed
    watch(currentTab, () => {
      selectedRows.value = [];

      if (currentTab.value !== WorkingHourTabs.GeneratedFiles) {
        getWorkingHours();
      }
    });

    watch([fromDate, toDate], () => {
      if ((fromDate.value && toDate.value) || (!fromDate.value && !toDate.value)) {
        getWorkingHours();
      }
    });

    const getWorkingHours = async () => {
      loading.value = true;
      workingHours.value = (
        await api.economy.getWorkingHoursAsync({
          IncludeEmployeeHours: false,
          IsSelfEmployed: false,
          Statuses:
            currentTab.value === WorkingHourTabs.ApprovedWorkingHours
              ? [WorkingHourStatusEnum.Approved]
              : [WorkingHourStatusEnum.PaymentPending],
          FromDate: fromDate.value,
          ToDate: toDate.value,
        })
      ).data.filter((x) => {
        if (currentTab.value === WorkingHourTabs.ApprovedWorkingHours) {
          if (!x.courseContractLinePrice) {
            // If the line's price is 0, it shouldn't be included in the list
            return false;
          }
          return true;
        }

        return true;
      });
      loading.value = false;
    };

    const submitWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.economy.setPaymentPendingWorkingHourAsync(selectedRows.value.map((workingHour) => workingHour.id));
        openNotification(store, NotificationItemType.Success, "CSV-fil lagret");
        await getWorkingHours();
        selectedRows.value = [];
      });
    };

    onMounted(async () => {
      await getWorkingHours();
    });

    return {
      workingHours,
      currentTab,
      selectedRows,
      fromDate,
      toDate,
      search,
      loading,
      disableButton: computed(() => selectedRows.value.length === 0),
      submitWorkingHours,
      getWorkingHours,
      WorkingHourStatusEnum,
      WorkingHourTabs,
      updateItemSelected: (newSelectedRows: ApiGetWorkingHourDto[]) => (selectedRows.value = newSelectedRows),
    };
  },
});
