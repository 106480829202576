var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTableFiltered', {
    attrs: {
      "value": _vm.value,
      "headers": _vm.headers,
      "items": _vm.workingHours,
      "search": _vm.search,
      "sort-by": "approvedByDate",
      "sort-desc": true,
      "item-key": "id",
      "loading": _vm.loading,
      "itemsPerPage": -1,
      "show-select": _vm.isStatusApproved
    },
    on: {
      "item-selected": _vm.itemIsSelected,
      "toggle-select-all": _vm.toggleAll
    },
    scopedSlots: _vm._u([{
      key: "item.date",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "item.approvedByDate",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "item.comment",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "max-width": "50%",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "commentTooltip"
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-comment-text-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(value))])]) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "syncUserInfoBtn"
                },
                on: {
                  "click": function click($event) {
                    return _vm.syncUserInfo(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-account-sync-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Synkroniser med Visma")])])];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }