var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.paymentPendingWorkingHours,
      "sort-by": "fileGeneratedByDate",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.fileGeneratedByDate",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "downloadCsvBtn"
                },
                on: {
                  "click": function click($event) {
                    return _vm.downloadWorkingHourCsv(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-file-download-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Last ned CSV")])])];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }