
import { api } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "WorkingHoursTable",
  components: { BaseTableFiltered },
  props: {
    status: {
      type: String,
      required: true,
    },
    workingHours: {
      type: Array as PropType<ApiGetWorkingHourDto[]>,
      required: true,
    },
    value: {
      type: Array as PropType<ApiGetWorkingHourDto[]>,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const isStatusApproved = computed(() => props.status === WorkingHourStatusEnum.Approved);

    const syncUserInfo = async (workingHourEntry: ApiGetWorkingHourDto) => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.user.syncErpPayrollEmployee(workingHourEntry.userId);
        openNotification(
          store,
          NotificationItemType.Success,
          `Bruker ${workingHourEntry.userFullName} synkronisert med Visma`
        );
        emit("updateWorkingHours");
      });
    };

    const headers = computed(() => [
      { text: "Kurs-ID", value: "courseId" },
      ...(isStatusApproved.value ? [{ text: "Handlinger", value: "actions" }] : []),
      { text: "Kursnavn", value: "courseName" },
      { text: "Lønnsart", value: "costPayType" },
      { text: "Kategori", value: "costTypeName" },
      { text: "Ressurs-ID", value: "userId" },
      { text: "Ressursnavn", value: "userFullName" },
      { text: "Ansnr.", value: "erpPayrollEmployeeId" },
      { text: "Avd.", value: "courseEconomyAccountingDepartment" },
      { text: "Prodnr.", value: "courseAreaErpProductNo" },
      { text: "Dato", value: "date" },
      { text: "Timer", value: "amount" },
      { text: "Pris", value: "courseContractLinePrice" },
      { text: "Kommentar", value: "comment" },
      { text: "Godkjent dato", value: "approvedByDate" },
      { text: "Godkjent av", value: "approvedByUserFullName" },
    ]);

    const itemIsSelected = (selectedItem: { item: ApiGetWorkingHourDto; value: boolean }) => {
      let newSelectedRows = props.value;
      if (selectedItem.value) {
        newSelectedRows = newSelectedRows?.concat(selectedItem.item);
      } else {
        newSelectedRows = newSelectedRows?.filter((x) => x.id !== selectedItem.item.id);
      }

      emit("input", newSelectedRows);
    };

    const toggleAll = (selectedItems: { items: ApiGetWorkingHourDto[]; value: boolean }) => {
      if (selectedItems.value) {
        emit("input", props.workingHours);
      } else {
        emit("input", []);
      }
    };

    return {
      headers,
      isStatusApproved,
      syncUserInfo,
      formatLocalizedDate,
      itemIsSelected,
      toggleAll,
    };
  },
});
