var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs",
      fn: function fn() {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function callback($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.WorkingHourTabs.ApprovedWorkingHours),
            "data-cy": "tabTitleWorkingHoursApproved"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-check ")]), _c('span', [_vm._v("Godkjente timer")])], 1), _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.WorkingHourTabs.PaymentPendingWorkingHours),
            "data-cy": "tabTitleWorkingHoursPaymentPending"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-check-all ")]), _c('span', [_vm._v("Timer til utbetaling")])], 1), _c('v-tab', {
          attrs: {
            "href": "#".concat(_vm.WorkingHourTabs.GeneratedFiles),
            "data-cy": "tabTitleWorkingHoursPaymentPendingFiles"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-file ")]), _c('span', [_vm._v("Genererte filer")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleWorkingHours"
          }
        }, [_vm._v("Godkjente arbeidstimer")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.currentTab !== _vm.WorkingHourTabs.GeneratedFiles ? _c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-text-field', {
          staticClass: "mr-3",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1), _c('v-col', {
          staticClass: "mr-3",
          attrs: {
            "cols": "3"
          }
        }, [_c('BaseDatePicker', {
          attrs: {
            "readonly": false,
            "label": "Fra",
            "single-line": "",
            "hide-details": "",
            "clearable": ""
          },
          model: {
            value: _vm.fromDate,
            callback: function callback($$v) {
              _vm.fromDate = $$v;
            },
            expression: "fromDate"
          }
        })], 1), _c('v-col', {
          staticClass: "mr-3",
          attrs: {
            "cols": "3"
          }
        }, [_c('BaseDatePicker', {
          attrs: {
            "readonly": false,
            "label": "Til",
            "single-line": "",
            "hide-details": "",
            "clearable": ""
          },
          model: {
            value: _vm.toDate,
            callback: function callback($$v) {
              _vm.toDate = $$v;
            },
            expression: "toDate"
          }
        })], 1), _vm.currentTab === _vm.WorkingHourTabs.ApprovedWorkingHours ? _c('v-col', {
          staticClass: "mr-3",
          attrs: {
            "cols": "2"
          }
        }, [_c('v-btn', {
          attrs: {
            "disabled": _vm.disableButton,
            "outlined": "",
            "color": "white"
          },
          on: {
            "click": function click($event) {
              return _vm.submitWorkingHours();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-content-save")]), _vm._v(" Lagre som CSV ")], 1)], 1) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "data-cy": "tabItemWorkingHoursApproved",
      "value": _vm.WorkingHourTabs.ApprovedWorkingHours
    }
  }, [_c('WorkingHoursTable', {
    attrs: {
      "status": _vm.WorkingHourStatusEnum.Approved,
      "workingHours": _vm.workingHours,
      "search": _vm.search,
      "loading": _vm.loading
    },
    on: {
      "updateWorkingHours": _vm.getWorkingHours
    },
    model: {
      value: _vm.selectedRows,
      callback: function callback($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "data-cy": "tabItemWorkingHoursPaymentPending",
      "value": _vm.WorkingHourTabs.PaymentPendingWorkingHours
    }
  }, [_c('WorkingHoursTable', {
    attrs: {
      "status": _vm.WorkingHourStatusEnum.PaymentPending,
      "workingHours": _vm.workingHours,
      "search": _vm.search,
      "loading": _vm.loading
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "data-cy": "tabItemWorkingHoursPaymentPendingFiles",
      "value": _vm.WorkingHourTabs.GeneratedFiles
    }
  }, [_c('WorkingHoursGeneratedFilesTable')], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }